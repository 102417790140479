.Sign{
    background-color: #ffffff;
    width: 1200px;
    margin: 30px auto;
    padding-bottom: 200px;
    font-size: 24px;
    position: relative;
    display: flex;
    text-align: center;

}
.Sign .record{
    width: 55%;
    padding: 30px;
    border: #333 solid 1px;
    border-radius: 20px;
    box-shadow: 10px 10px 10px #989696;
    height: 700px;
    background-color: #f3f9ff;
    overflow: auto;
}
.Sign .start{
    width: 45%;
    margin-top: 20px;
    padding: 50px;
    
}

.Sign .title{
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 30px;
    padding-bottom: 20px;
    color: #ec4343;
    text-shadow: #d9c4c4 2px 2px 2px;
    border-bottom: #f4eb6d 4px dotted;
}

.Sign .start-input{
    width:400px;
    margin: 0 auto;
    font-size: 30px !important;
}

.Sign .start-input input{
    height: 60px;
    line-height: 50px;
    font-size: 30px;
    border-radius: 10px;
}

.Sign .btn-start{
    width: 400px;
    font-size: 24px !important;
    /* border: #333 2px solid; */
    border-radius: 10px;
    margin-top: 30px;
    padding: 10px;
    color: #ffffff;
    /* box-shadow: #ec4343 5px 5px 5px; */
    background-color: green;
}


.Sign .item{
   display: flex;
   margin: 15px 0;
   color: #5151ad;
   text-align: left;
   font-size: 15px;
}

.Sign .item1{
    width: 60px;
    margin-right: 10px;
 }

 .Sign .item2{
    width: 60px;
    margin-right: 10px;
 }

 .Sign .item3{
    width: 300px;
    margin-right: 10px;
 }
 .Sign .item4{
    
 }


.Sign .active{
    background-color: red;
}

.Sign-login{
    border: 1px solid #333;
    width: 600px;
    height: 350px;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    padding: 20px;
    border-radius: 10px;
    background-image: linear-gradient(to right,#1a6a8f,#21938c);
    background-size: 100% 100%;
    color: #ffffff;
}
.Sign-login .title{
  text-align: center;
  margin-bottom: 10px;
}


.Sign-login .item{
    width: 350px;
    margin:auto;
}

.Sign-login .btn-login{
    text-align: center;
}

.Sign-login .btn-login button{
    background-image: linear-gradient(to bottom,#3c45ef,#908310);
    color: #fff;
    border-radius: 10px;
    padding: 10px 20px;
}

.Sign .result{
    text-align: left;
    padding: 20px 20px;
    font-size: 20px;
}

.Sign .result .item22{
    display: flex;
    
    margin: 10px 0;
}
.Sign .suo{
    flex:none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.Sign .success{
    color: #ba2727;
    text-align: center;
    font-size: 35px;
    margin-top: 40px;
}

.Sign .err{
    font-size: 25px;
    margin: auto;
    color: red !important;
    margin-top: 20px;
    width: 400px;
    text-align: left;
    
}

.Sign .start .total{
    text-align: left;
    margin-left: 20px;
    margin-bottom: 20px;
}





