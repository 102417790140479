.moment{
    margin-top: 600px;
    
    color: #999;
    text-align: center;
    
}
.moment h2{
    font-size: 30px !important;
}
.moment_kuang{
    max-width: 750px;
    margin: 0 auto;
    position: relative;
}