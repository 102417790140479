

.video-bg{
    background-color: black;
  
    width: 750px;
    height: 100vh;
    
    overflow: hidden;
    position: relative;
    margin: 0 auto;
  }
  #start {
    position: absolute;
    left: 50%;
    top: 50%;
    
   
    width: 200px;
    transform: translate(-50%,1000%);
   
    font-size: 30px;
    z-index: 999;
    text-align: center;
    
    
  }

  #stop {
    position: absolute;
    right: 20px;
    bottom: 50px;
    width: 200px;
    height: 50px;
    font-size: 30px;
    z-index: 999;
    color: white;
    display: none;
  }

  #myvideo1 {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 750px;
  }