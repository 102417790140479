
.application{
    position: relative;
    flex-direction: row;
    justify-content: center;
    width: 750px;
    margin: 0 auto;
}
.successItem{
    font-size: 24px;
    font-weight: 400;
    max-width: 750px;
    margin: 0 auto;
    
    padding-bottom: 200px;
    position: relative !important;
}
.successItem .bg{
    width: 750px;
    text-align: center;
}
.successItem .success{
   margin: 100px 0;
   margin-top: 190px;
   text-align: center;
   margin-bottom: 60px;
}
.successItem .info{
    text-align: center;
 }
.successItem .info .erweima{
   width: 220px;
   height: 220px;
   padding: 10px;
   border: 1px solid #8eb500;
   border-radius: 20px;
}
.successItem .info .text1{
    font-size: 18px;
    margin-top: 10px;
 }
 .successItem .info .text2 h2{
    margin-top: 20px;
    color: #8eb500;
    font-weight: 400 !important;
 }
 .successItem .info .text3 h2{
   margin-top: 0px;
   color: #8eb500;
   font-weight: 400 !important;
}
 .successItem .shuoming{
    text-align: left;
    margin: 50px 80px;
    line-height: 30px;
 }
 .successItem .shuoming-en{
   text-align: left;
   margin: 50px 80px;
   line-height: 30px;
}
 .successItem .shuoming-en p{
    margin-top: 25px !important;
    line-height: 25px;
    font-size: 17px !important;
 }

 .successItem .shuoming p{
   margin-top: 25px !important;
   line-height: 30px;
   font-size: 20px !important;
}

 .successItem .success img{
    width: 350px;
 }
 .application .translate {
    position: absolute;
    top: 280px;
    left:83px;

 }
 .successItem .translate {
    position: absolute;
    top: 680px;
    left:83px;
    
 }


.en{
    display: flex;
    margin-top: 10px;
}
.en .radio-inline {
    padding-left: 30px !important;
    line-height: 32px;
}