.home {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .home .div {
    background-color: var(--cbgwhite);
    border: 1px none;
    height: 2446px;
    overflow: hidden;
    position: relative;
    width: 780px;
  }
  
  .home .overlap-group-wrapper {
    height: 88px;
    left: 0;
    position: absolute;
    top: 88px;
    width: 780px;
  }
  
  .home .overlap-group {
    background-color: #ffffff;
    height: 88px;
    position: relative;
    width: 780px;
  }
  
  .home .text-wrapper {
    color: var(--ctextwbgprimary-90);
    font-family: var(--font-16-24-medium-font-family);
    font-size: var(--font-16-24-medium-font-size);
    font-style: var(--font-16-24-medium-font-style);
    font-weight: var(--font-16-24-medium-font-weight);
    left: 184px;
    letter-spacing: var(--font-16-24-medium-letter-spacing);
    line-height: var(--font-16-24-medium-line-height);
    position: absolute;
    text-align: center;
    top: 20px;
    white-space: nowrap;
  }
  
  .home .view {
    height: 56px;
    left: 692px;
    position: absolute;
    top: 4px;
    width: 56px;
  }
  
  .home .div-2 {
    background-color: #191919;
    border-radius: 3px;
    height: 6px;
    left: 24px;
    position: absolute;
    top: 24px;
    width: 6px;
  }
  
  .home .div-3 {
    background-color: #191919;
    border-radius: 3px;
    height: 6px;
    left: 12px;
    position: absolute;
    top: 24px;
    width: 6px;
  }
  
  .home .div-4 {
    background-color: #191919;
    border-radius: 3px;
    height: 6px;
    left: 40px;
    position: absolute;
    top: 24px;
    width: 6px;
  }
  
  .home .iphone-x-status-bars {
    background-color: #ffffff;
    height: 88px;
    left: 0;
    position: absolute;
    top: 0;
    width: 780px;
  }
  
  .home .battery {
    height: 24px;
    left: 694px;
    position: absolute;
    top: 36px;
    width: 48px;
  }
  
  .home .wifi {
    height: 24px;
    left: 650px;
    position: absolute;
    top: 36px;
    width: 32px;
  }
  
  .home .cellular-connection {
    height: 22px;
    left: 604px;
    position: absolute;
    top: 38px;
    width: 32px;
  }
  
  .home .time-style {
    height: 36px;
    left: 42px;
    overflow: hidden;
    position: absolute;
    top: 30px;
    width: 108px;
  }
  
  .home .time {
    color: #000000;
    font-family: "SF Pro Text-Semibold", Helvetica;
    font-size: 32px;
    font-weight: 400;
    left: 20px;
    letter-spacing: -1.2px;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .home .group {
    height: 68px;
    left: 0;
    position: absolute;
    top: 2378px;
    width: 780px;
  }
  
  .home .home-indicator-light {
    height: 68px;
    overflow: hidden;
  }
  
  .home .home-indicator-wrapper {
    background-color: #ffffff;
    height: 72px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 780px;
  }
  
  .home .home-indicator {
    background-color: #000000;
    border-radius: 5px;
    height: 14px;
    left: 252px;
    position: relative;
    top: 42px;
    width: 280px;
  }
  
  .home .overlap-wrapper {
    /* background-color: #f9f9f9; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    position: fixed;
    bottom: 0;
  }
  
  .home .overlap {
    background-color: #ffffff;
    height: 150px;
    /* left: -1px; */
    position: relative;
    top: 0px;
    width: 750px;
    padding: 0 45px;
    padding-top: 6px;
    display: flex;
    justify-content: space-between;

  }
  
  .home .overlap-2 {
    height: 88px;
    width: 82px;
    position: relative;
  }
  
  .home .view-2 {
    height: 56px;
    left: 14px;
    position: absolute;
    top: 0;
    width: 56px;
  }
  
  .home .overlap-group-2 {
    height: 36px;
    left: 10px;
    position: relative;
    top: 12px;
    width: 40px;
  }
  
  .home .rectangle {
    height: 4px;
    left: 0;
    position: absolute;
    top: 32px;
    width: 40px;
  }
  
  .home .img {
    height: 36px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 36px;
  }
  
  .home .text-wrapper-2 {
    color: #000000e6;
    font-family: "LiciumFont 2022-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 36px;
    opacity: 0.9;
    position: absolute;
    text-align: center;
    top: 52px;
    white-space: nowrap;
  }
  
  .home .overlap-3 {
    height: 88px;
    position: relative;
    width: 82px;
  }
  
  .home .overlap-4 {
    height: 88px;
    left: 38px;
    position: absolute;
    top: 6px;
    width: 82px;
  }
  
  .home .overlap-5 {
    height: 88px;
    position: relative;
    width: 82px;
  }
  
  
  .home .overlap-6 {
    height: 72px;
    left: 16px;
    position: relative;
    top: 20px;
    width: 80px;
  }
  
  .home .rectangle-2 {
    height: 32px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 36px;
  }
  
  .home .vector {
    height: 4px;
    left: 10px;
    position: absolute;
    top: 8px;
    width: 20px;
  }
  
  .home .vector-2 {
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 40px;
  }
  
  .home .vector-3 {
    height: 8px;
    left: 18px;
    position: absolute;
    top: 28px;
    width: 4px;
  }
  
  .home .vector-4 {
    height: 4px;
    left: 10px;
    position: absolute;
    top: 16px;
    width: 8px;
  }
  
  .home .overlap-7 {
    height: 88px;
    position: relative;
    width: 82px;
  }
  
  .home .img-1 {
    height: 80px;
    left: 12px;
    position: absolute;
    /* top: 6px; */
    width: 80px;
  }
  .home .img-2 {
    height: 80px;
    /* left: 4px; */
    position: absolute;
    /* top: 6px; */
    width: 80px;
  }
  .home .img-3 {
    height: 80px;
    left: 12px;
    position: absolute;
    /* top: 6px; */
    width: 80px;
  }
  .home .img-4 {
    height: 80px;
    left: 12px;
    position: absolute;
    /* top: 6px; */
    width: 80px;
  }
  .home .img-5 {
    height: 80px;
    /* left: 4px; */
    position: absolute;
    /* top: 6px; */
    width: 80px;
  }
  
  .home .mask-group {
    height: 780px;
    left: 0;
    position: absolute;
    top: 176px;
    width: 780px;
  }
  
  .home .text-wrapper-3 {
    color: var(--ctextwbgprimary-90);
    font-family: var(--font-18-26-medium-font-family);
    font-size: var(--font-18-26-medium-font-size);
    font-style: var(--font-18-26-medium-font-style);
    font-weight: var(--font-18-26-medium-font-weight);
    left: 40px;
    letter-spacing: var(--font-18-26-medium-letter-spacing);
    line-height: var(--font-18-26-medium-line-height);
    position: absolute;
    top: 1018px;
    width: 700px;
  }
  
  .home .text-wrapper-4 {
    color: var(--ctextwbgprimary-90);
    font-family: var(--font-14-22-regular-font-family);
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 40px;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    top: 1118px;
    width: 700px;
  }
  
  .home .text-wrapper-5 {
    color: var(--ctextwbgnormal-60);
    font-family: var(--font-14-22-regular-font-family);
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 40px;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    text-align: justify;
    top: 1194px;
    width: 700px;
  }

  .home .tuichu{
    color: #000000e6;
    font-size: 28px;
    line-height: 90px;
  }

  .home .img-tuichu{
    
    left: 4px;
    position: absolute;
    top: 5px;
    width: 45px;
  }

  