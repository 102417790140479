label {
    font-weight: 400 !important;
}

h4 {
    color: #96b414 !important;
    font-weight: 400 !important;
    font-size: 30px !important;
}

p {
    line-height: 50px;
    margin: 0 !important;
    font-weight: 400 !important;
    font-size: 24px !important;
}

div {
    font-weight: 400;
    /* font-size: 24px !important; */
}

.dash-form-control {
    border: 1px solid #040000 !important;
    background-color: #fff0 !important;
    height: 64px !important;
    font-size: 24px !important;
    padding: 6px 18px!important;
    border-radius: 6px!important;
}



.btn-submit {
    background-color: #96b414 !important;
    border: 0 !important;
}

.err {
    color: rgb(213, 69, 69) !important;
}

.form-group {
    margin-bottom: 25px !important;
    position: relative;
}

.chooseData {
    line-height: 60px !important;
    display: flex;
    margin-top: 5px;
}









 .xiaozi{
    font-size: 16px !important;
    margin: 0 !important;
    line-height: 12px !important;
    margin-bottom: 8px !important;
    color: #96b414;
}
.xiaozi2{
    font-size: 18px !important;
    margin-bottom: 20px !important;
    line-height: 20px !important;
    color: #96b414;
}
.container{
    width: 100% !important;
}

select{
    appearance:none;
    -moz-appearance:none;
    -webkit-appearance:none;
    -ms-appearance:none;
} 

.dash-jiantou{
    position: absolute;
    width: 15%;
    right: 15%;
    bottom: 40%;
}
.jiantou_kuang{
    position: relative;
}
.label_eng{
    font-size: 16px!important;
    line-height: 16px;
    margin-bottom: 14px;
    margin-top: 5px;
}
.label_eng_title{
    font-size: 18px!important;
}
.label_eng_text{
    font-size: 15px!important;
}
.title{
    font-size: 22px;
}
.dash-title1{
    margin-bottom: 5px;
}
.label_eng_title_no{
    margin-left: 180px !important;
}
.label_eng_text_test{
    margin-left: 74px !important;
}
.label_eng_title_pass{
    margin-left: 110px !important;
}
.radio-inline-lang{
    padding-left: 30px !important;
}
.input-text{
    margin-top: 15px !important;
    border: 1px solid #040000 !important;
    background-color: #fff0 !important;
    height: 68px !important;
    font-size: 24px !important;
    padding: 6px 18px!important;
    border-radius: 6px!important;
}
.baoming{
    height: 70px!important;
    font-size: 26px!important;
} 

.form-control-home {
    border: 1px solid #040000 !important;
    background-color: #fff0 !important;
    height: 64px !important;
    font-size: 24px !important;
    padding: 6px 18px!important;
    border-radius: 6px !important;
    margin-top: 15px;
}
.jiantou2{
    position: absolute;
    width: 3%;
    right: 3%;
    bottom: 40%;
    pointer-events: none
}
.application2{
    position: relative;
}

.application2 .translate{
    margin-left: 83px;
    margin-top: 70px;
}

.application2 .liuyi{
    font-size: 18px;
    margin-top: 8px;
    font-weight: 300;
}

.application2 .kv{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
}

.application2 .liuguang{
    position: absolute;
    top: 300px;
    left: 150px;
    background-color: #040000;
    width: 450px;
    height: 230px;
    opacity: 0.5;
    background: -webkit-linear-gradient(left,rgba(255,255,255,0)0,rgba(255,255,255,0.5)50%,rgba(255,255,255,0)100%) no-repeat -270px 0;
}

.application2 .yaoqing{
    position: absolute;
    top: 0;
    left: 0;
    width: 750px;
    z-index: 999999;
    animation-name: dong_ani;
    animation-duration: 2.5s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
    opacity: 1;
}
.application2 .dianji{
    position: absolute;
    width: 750px;
    text-align: center;
    top: 900px;
    font-size: 40px;
    color: #fff;
    z-index: 9999999;
}

@keyframes dong_ani {
    from {
    }
        
    to {
        opacity: 0;
    }
}

  
  