.kv {
    width: 750px;
    height: 100%;
    
    overflow: hidden;
    position: relative;
}

.bg {
    width: 750px;
}

.kv .logo1 {
    position: absolute;
    top: 123px;
    left: 336px;
    width: 80px;
    opacity: 0;
    animation-name: logo_ani;
    animation-duration: 0.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    
}

.kv .title {
    position: absolute;
    top: -100px;
    left: 190px;
    width: 370px;
    animation-name: title_ani;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;

    
}

/* .men1{
    width: 375px;
    font-size: 0;
    background-color:rgb(114, 96, 73);
    position: absolute;
    left:0;
    animation-name: kaimen1;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    height: 100%;
    background-image: linear-gradient(to right,rgb(98 138 168), rgb(87 128 158));
    
}
.men2{
    font-size: 0;
    width: 375px;
    background-color:rgb(114, 96, 73);
    position: absolute;
    left:375px;
    animation-name: kaimen2;
    animation-timing-function: ease;
    animation-duration: 2s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    height: 100%;
    background-image: linear-gradient(to right, rgb(87 128 158), rgb(68 108 141));
} */
.kv .men1 img{
    width: 100%;
    background-image: linear-gradient(to right,rgb(98 138 168), rgb(87 128 158));
}
.kv .men2 img{
    width: 100%;
    background-image: linear-gradient(to right, rgb(87 128 158), rgb(68 108 141));
   
}

.kv .dong{
    animation-name: dong_ani;
    animation-duration: 2.5s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    opacity: 1;
}

.kv .bg{
    width: 750px;
    position: absolute;
    height: 100%;
    background-image: linear-gradient(to right,rgb(98 138 168), rgb(87 128 158), rgb(68 108 141));
}
.kv .bg img{
    width: 100%;
    background-image: linear-gradient(to right,rgb(98 138 168), rgb(87 128 158), rgb(68 108 141));
    
}



@keyframes kaimen1 {
    from {
        
    }
    to {
        
        left: -375px;
    }
}
@keyframes kaimen2 {
    from {
        
    }
    to {
        
        left: 750px;
    }
}


@keyframes logo_ani {
    from {}

    to {
        opacity: 1;
    }
}
@keyframes title_ani {
    from {}

    to {
        top:435px;
    }
}

@keyframes dong_ani {
    from {
    }
        
    to {
        opacity: 0;
    }
}



  
